import { filter } from 'rxjs/operators';
import { FNOL_STEPS_NO_HLF, FNOL_STEPS_NO_TP } from './../constants/fnol-nordics.constant';
import { Injectable } from '@angular/core';
import { FormService } from "axis-ui-generator";
import { FnolService } from '../../services/fnol.service';
import { Utils } from "axis-ui-generator";
import { Title } from '@angular/platform-browser';
import { Configuration } from 'src/app/shared/configuration';
import {CUSTOMERRORMESSAGE, FNOL_STEPS_NO_AH, lossTypeDocumentsList } from "../constants/fnol-nordics.constant";
import { stepChangeArray,CUSTOMPHONE, CUSTOMACCOUNT, stepChangeArrayHLF } from '../constants/fnol-norway-travel.constant';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError as observableThrowError, BehaviorSubject, Observable, Subject } from 'rxjs';
import { DataStoreService } from '../../services/data-store.service';
import { FnolNordicsApiService } from './common-nordics-api-services';
import * as moment from 'moment';
import { landingPageLabelsAhNO, landingPageLabelsAh} from "../constants/fnol-norway-travel.constant";
export interface Step {
  current: string;
  next: string;
  prev: string;
  completedSlNo: number;
}
@Injectable({
  providedIn: 'root'
})

export class CommonNordicsUtilsService {

  indexList = {
    addPerson: 2

  }
  public continueClicked=new BehaviorSubject<any>({});
  anotherPersonAdded: boolean = false;
  constructor(private augFormService: FormService,private fnolService: FnolService,
    private config: Configuration,private titleService: Title,  private http: HttpClient,
    private nordicsApiService: FnolNordicsApiService,private dataStoreService: DataStoreService) {

  }
  public addClicked=new BehaviorSubject<any>(true);
  public addClickedSupplemental=new BehaviorSubject<any>(false);
  public addClickedMulIncident=new BehaviorSubject<any>(false);
  countryLob;
  pageLabels =landingPageLabelsAh;
  // selectedLossType = [];
  uiData;
  disabled=true;
  augRefresh = {};
  placeIdsList=[];
  language;//no/en
  multiSelect;
   augRefreshEvent=new BehaviorSubject<any>(false)
  extractPathParam(type) {
    const path = window.location.pathname.split("/");
    return path[type]
  }

  extractCountryLobLanguage() {
    const path = window.location.pathname.split("/");
    let p = path[4] + "_" + path[3] + "_" + path[5];
    this.countryLob=p;
    return p;
  }
  setLanguage() {
  this.language = window.location.pathname.split('/')[4];
  }
  getLanguage() {
    return this.language;
  }

   insertPhoneNumberCustom(uiStructure,containerName,index,name){
    if(Utils.getFieldFromStructure(uiStructure, containerName).fields[index].name !== name ){

      Utils.getFieldFromStructure(uiStructure, containerName).fields.splice(index,0,CUSTOMPHONE);
    }


   }


   insertAccountNumberCustom(uiStructure,containerName,index,name){
    if(Utils.getFieldFromStructure(uiStructure, containerName).fields[index].name !== name ){

      Utils.getFieldFromStructure(uiStructure, containerName).fields.splice(index,0,CUSTOMACCOUNT);
    }


   }
   checkMaxTime(currentTime,newTime,currentSectionName,controlName,augFormService){

    this.augFormService=augFormService;
    const currTime = currentTime.getHours() * 60 + currentTime.getMinutes();
    const setTime = newTime.getHours() * 60 + newTime.getMinutes();
        if ( setTime > currTime && controlName === "theftBaggageTime") {
      this.augFormService.Form[currentSectionName]
        .get(controlName)
        .setErrors({
          custom: { errorMessage: this.language === 'en' ? CUSTOMERRORMESSAGE.incidentTime:CUSTOMERRORMESSAGE.incidentTimeNor },
        });
      this.augFormService.Form[currentSectionName]
        .get(controlName)
        .markAsTouched(); // set mark as touched.
    }
    else if(setTime < currTime && controlName === "boxConfirmedTime"){
      this.augFormService.Form[currentSectionName]
      .get(controlName)
      .setErrors({
        custom: { errorMessage: this.language === 'en' ? CUSTOMERRORMESSAGE.BagLostTime:CUSTOMERRORMESSAGE.BagLostTimeNor },
      });
    this.augFormService.Form[currentSectionName]
      .get(controlName)
      .markAsTouched();
    } else if((setTime < currTime || setTime === currTime) && controlName === "scheduledArrivalTime"){
      this.augFormService.Form[currentSectionName]
      .get(controlName)
      .setErrors({
        custom: { errorMessage: this.language === 'en' ? CUSTOMERRORMESSAGE.scheArrivalTime:CUSTOMERRORMESSAGE.scheArrivalTimeNor },
      });
    this.augFormService.Form[currentSectionName]
      .get(controlName)
      .markAsTouched();

    }
    else if((setTime < currTime || setTime === currTime) && controlName === "actualArrivalTime"){
      this.augFormService.Form[currentSectionName]
      .get(controlName)
      .setErrors({
        custom: { errorMessage: this.language === 'en' ? CUSTOMERRORMESSAGE.actualArrivalTime:CUSTOMERRORMESSAGE.actualArrivalTimeNor },
      });
    this.augFormService.Form[currentSectionName]
      .get(controlName)
      .markAsTouched();

    }
    else{
      augFormService.Form[currentSectionName]
        .get(controlName)
        .setErrors(null);
    }

  }
  checkMaxTimewithDate(currentTime,newTime,currentSectionName,controlName,augFormService,from,to,uiData){
 const currTime = currentTime.getHours() * 60 + currentTime.getMinutes();
    const setTime = newTime.getHours() * 60 + newTime.getMinutes();
    if ( setTime < currTime && (moment(uiData[from]).format("DD-MM-YYYY") === moment(uiData[to]).format("DD-MM-YYYY"))) {
            this.augFormService.Form[currentSectionName]
        .get(controlName)
        .setErrors({
          custom: { errorMessage: this.language === 'en' ? CUSTOMERRORMESSAGE.scheArrivalTime:CUSTOMERRORMESSAGE.scheArrivalTimeNor },
        });
      this.augFormService.Form[currentSectionName]
        .get(controlName)
        .markAsTouched(); // set mark as touched.
    } else {
      this.augFormService.Form[currentSectionName]
      .get(controlName)
      .setErrors(null); 
    }
  }
   getCountryList(){
    let countryList;
    let countryArray=[]
    this.fnolService.getUnrRdfEnumList('country').subscribe(res => {
      if (res.getEnumerationResponse) {
      countryList = res.getEnumerationResponse.enumerationResponse.enumCollection.item;
      let sortedCountryList=this.sortJsonArrayByProperty(countryList,"value",1);
      if(this.language==='no'){
        sortedCountryList=this.sortJsonArrayByProperty(countryList,"value_no",1);
      }
      sortedCountryList.forEach(country => {
          if(this.language === 'no') {
          countryArray.push({
            code: country['key'].trim(),
            id: country['key'].trim(),
            name: country['value_no'],
            rpaValue: country['key'].trim()
          });
        } else {
          countryArray.push({
            code: country['key'].trim(),
            id: country['key'].trim(),
            name: country['value'],
            rpaValue: country['key'].trim()
          });
        }
        });

      }
    });

    return countryArray;
   }
   CheckDateValidation(startDate,endDate,currentSectionName,controlName,augFormService){
    if (startDate > endDate && controlName === "returnDate") {
      this.augFormService=augFormService;
      this.augFormService.Form[currentSectionName]
        .get("returnDate")
        .setErrors({
          custom: { errorMessage: this.language === 'en' ? CUSTOMERRORMESSAGE.startDate:CUSTOMERRORMESSAGE.startDateNor },
        });
        this.augFormService.Form[currentSectionName]
        .get("returnDate")
        .markAsTouched();
    }
    else if (startDate > endDate && controlName === "boxConfirmedDate") {
      this.augFormService=augFormService;
      this.augFormService.Form[currentSectionName]
        .get(controlName)
        .setErrors({
          custom: { errorMessage: this.language === 'en' ? CUSTOMERRORMESSAGE.BagLostDate:CUSTOMERRORMESSAGE.BagLostDateNor },
        });
        this.augFormService.Form[currentSectionName]
        .get(controlName)
        .markAsTouched();
    }
    else{
      augFormService.Form[currentSectionName]
        .get(controlName)
        .setErrors(null);
    }
  }

  setAddresslookup(e,currentSectionName,augFormService,fieldName,uiData,uiStructure) {
    const matches = fieldName.match(/(\d+)/);
    const i = matches!=null?matches[0]:undefined;
    const fieldId =i!=undefined?i:"";
    const address = e.form.eventInfo.uiData[fieldName]?.address_components;
    this.uiData=uiData; 
   let fieldText =  Utils.getFieldFromStructure(uiStructure, e.form.eventInfo.eventSource.containerDependsOn.split(',')[0]+fieldId).options.filter(element=>element.name === this.uiData[e.form.eventInfo.eventSource.containerDependsOn.split(',')[0]+fieldId].name)[0].key;
    this.augFormService = augFormService;
    if(e.form.eventInfo.uiData[fieldName].place_id!=undefined || e.form.eventInfo.uiData[fieldName]?.place_id!=null){
      if(this.placeIdsList.find(field=>field.name==fieldName)==undefined){
        this.placeIdsList.push({name:fieldName,place_Id:e.form.eventInfo.uiData[fieldName]?.place_id});
      }else{
        this.placeIdsList.find(field=>field.name==fieldName).place_Id=e.form.eventInfo.uiData[fieldName]?.place_id;
      }
    }

    this.nordicsApiService.setAddressesPlaceIDs(this.placeIdsList);
    if (address && address.length) {
      const country =  Utils.getFieldFromStructure(uiStructure,fieldText + 'Country'+ fieldId).options.filter(i=>i.code === this.fnolService.getDataByCode('country', address))[0];
      this.uiData[`${fieldText + 'Country'+ fieldId}`] = country;
      this.augFormService.Form[currentSectionName].get(fieldText + 'Country'+ fieldId).setValue(country);
      const postalCode = this.fnolService.getDataByLevel(
        "postal_code",
        address
      );
      if(fieldName.toLowerCase().indexOf("mailingaddress")>-1){
        this.uiData[fieldName+"val"]= this.uiData[fieldName].viewModel;
        this.uiData[fieldName]=this.uiData[fieldName].viewModel;

      }
      if (postalCode) {
          this.uiData[fieldText + 'PostalCode'+ fieldId]=postalCode;
        this.augFormService.Form[currentSectionName]
          .get(fieldText + 'PostalCode'+ fieldId)
          .setValue( this.uiData[fieldText + 'PostalCode'+ fieldId]);

      }
      this.augFormService.Form[currentSectionName]
        .get(fieldText + 'PostalCode'+ fieldId)
        .markAsTouched();
        let city = "";
        city = this.fnolService.getDataByLevel('locality', address);
        let state = this.fnolService.getDataByLevel('administrative_area_level_1', address);
        if (!city) {
          if(this.fnolService.getDataByLevel('postal_town', address)){
            city = this.fnolService.getDataByLevel('postal_town', address);
          }
          else {
            city = this.fnolService.getDataByLevel('sublocality_level_1', address);
          }
        
        }
      if(city!=""){
        if(country.id === 'US'){
          const cityUS = city+', '+state;
          this.uiData[fieldText + "City" + fieldId]= cityUS;
        }
        else{
          this.uiData[fieldText + "City" + fieldId]= city;
        }
        this.augFormService.Form[currentSectionName]
          .get(fieldText + "City" + fieldId)
          .setValue(this.uiData[fieldText + "City" + fieldId]);

      }
      this.augFormService.Form[currentSectionName]
        .get(fieldText + "City" + fieldId)
        .markAsTouched();

      this.augFormService.detectChanges.next();
    }
    else if (this.uiData[fieldName]){
      this.uiData[fieldName+"val"]= this.uiData[fieldName];
    }

  }

  public fromToDateValidation(e,currentSectionName,uiData,uiStructure,getMainParent, fromDate, toDate, dateSameDisplay, toDateLesser, fromDateGreater): void {
    let arr = [];
    Utils.getFieldFromStructure(uiStructure, getMainParent).fields.forEach((a,index) => {
      arr.push(index)
    });
 
    arr.forEach((el) => {
      let indexList=el === 0?"":el+1
      const firstDate = e.form.model.controls[fromDate + indexList]?.value;
      const lastDate = e.form.model.controls[toDate + indexList]?.value;


      let enddateObj = Utils.getFieldFromStructure(uiStructure, toDate + indexList);
      enddateObj.minDate=new Date(firstDate)
    });
     this.augRefreshEvent.next(true)
   
  }

  sortJsonArrayByProperty(objArray, prop, direction){
    if (arguments.length<2) throw new Error("ARRAY, AND OBJECT PROPERTY MINIMUM ARGUMENTS, OPTIONAL DIRECTION");
    if (!Array.isArray(objArray)) throw new Error("FIRST ARGUMENT NOT AN ARRAY");
    const clone = objArray.slice(0);
    const direct = arguments.length>2 ? arguments[2] : 1; //Default to ascending
    const propPath = (prop.constructor===Array) ? prop : prop.split(".");
    clone.sort(function(a,b){
        for (let p in propPath){
                if (a[propPath[p]] && b[propPath[p]]){
                    a = a[propPath[p]];
                    b = b[propPath[p]];
                }
        }
        // convert numeric strings to integers
        a = a.toLowerCase().match(/^\d+$/) ? +a.toLowerCase() : a.toLowerCase();
        b = b.toLowerCase().match(/^\d+$/) ? +b.toLowerCase() : b.toLowerCase();
        return ( (a < b) ? -1*direct : ((a > b) ? 1*direct : 0) );
    });
    return clone;
}
getCountry(){
  let countryList;
  let countryArray=[];
  this.fnolService.getUnrRdfEnumList('country').subscribe(res => {
    if (res.getEnumerationResponse) {
    countryList = res.getEnumerationResponse.enumerationResponse.enumCollection.item;
    let sortedCountryList=this.sortJsonArrayByProperty(countryList,"value",1);
    if(this.language==='no'){
      sortedCountryList=this.sortJsonArrayByProperty(countryList,"value_no",1);
    }
    
    sortedCountryList.forEach(country => {
        if(this.language === 'no') {
          countryArray.push({
            code: country['key'].trim(),
            id: country['key'].trim(),
            name: country['value_no'],
            rpaValue: country['key'].trim()
          });
        } else {
          countryArray.push({
            code: country['key'].trim(),
            id: country['key'].trim(),
            name: country['value'],
            rpaValue: country['key'].trim()
          });
        }
      });

    }
  });

  return countryArray;
}

  getClaimBenefitType(countryParam: string, countryObj): Array<any> {
    let claimBenefitType = [];
    if (countryObj[countryParam].countryCode === countryObj.en_no_tp.countryCode) {
      claimBenefitType = [{
        id: '1',
        name: $localize`:@@Medicalexpense:Medical Expense`
      },
      {
        id: '2',
        name: $localize`:@@AccidentalDeath:Accidental Death`
      },
      {
        id: '3',
        name: $localize`:@@permanentDisability:Permanent Disability`
      }];
    }
    return claimBenefitType;
  }
  getIconsNordics(uploadSelected, imagePreview): void {
    for (const axisFile of uploadSelected) {
      const file = axisFile.file;

      let icon = axisFile.icon,
        img = false,
        uploadIcon = "";
      if (icon === undefined) {
        if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          icon = "xlsx";
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          icon = "docx";
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ) {
          icon = "pptx";
        } else if (file.type.match("^audio/")) {
          icon = "music_note";
        } else if (file.type.match("^image/gif")) {
          icon = "image";
          img = true;
        } else if (file.type.match("^image/jpeg")) {
          icon = "jpg";
          img = true;
        } else if (file.type.match("^image/png")) {
          icon = "png";
          img = true;
        } else if (file.type.match("^image/")) {
          icon = "image";
        } else if (file.type.match("pdf")) {
          icon = "pdf";
        } else if (file.type.match("text/csv")) {
          icon = "csv";
        } else if (file.type.match("^video/")) {
          icon = "video";
        } else if (file.type.match("text") || file.type === undefined) {
          icon = "txt";
        } else if (file.name.match(".ppt$")) {
          icon = "ppt";
        } else if (file.name.match(".xls$")) {
          icon = "xls";
        } else {
          icon = "txt";
        }
      }

      switch (icon) {
        case "avi":
          uploadIcon = "video";
          break;
        case "doc":
        case "docx":
          uploadIcon = "doc";
          break;
        case "gif":
          img = true;
          uploadIcon = "image";
          break;
        case "flv":
          uploadIcon = "video";
          break;
        case "jpeg":
          img = true;
          uploadIcon = "jpg";
          break;
        case "m4a":
          uploadIcon = "video";
          break;
        case "mov":
          uploadIcon = "video";
          break;
        case "mp3":
          uploadIcon = "music_note";
          break;
        case "mp4":
          uploadIcon = "video";
          break;
        case "ogg":
          uploadIcon = "video";
          break;
        case "png":
          img = true;
          uploadIcon = "png";
          break;
        case "ppt":
        case "pptx":
          uploadIcon = "picture_as_ppt";
          break;
        case "txt":
          uploadIcon = "picture_as_doc";
          break;
        case "xls":
        case "xlsx":
          uploadIcon = "picture_as_xls";
          break;
        case "tif":
        case "tiff":
          uploadIcon = "image";
          break;
        case "webm":
        case "wmv":
          uploadIcon = "video";
          break;
        default:
          uploadIcon = icon;
          break;
      }
      axisFile.img = imagePreview ? img : false;
      axisFile.uploadIcon = uploadIcon;
    }

    return uploadSelected;
  }
  getNowNordics(type): string {
    let value;
    const today = new Date();
    if (type === "date") {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      value =
        months[today.getMonth()] +
        " " +
        today.getDate() +
        ", " +
        today.getFullYear();
    } else {
      let hours = today.getHours();
      const minutesNum = today.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      const minutes =
        minutesNum < 10 ? "0" + minutesNum.toString() : minutesNum;
      value = hours.toString() + ":" + minutes.toString() + " " + ampm;
    }
    return value;
  }

  getAnotherPersonAddedValue() {
    return this.anotherPersonAdded;
  }

  public duplicateContainer(obj, uiStructure,uiData={},selectedPersonsData=[]): void {
    if(selectedPersonsData[0]?.value === "employer") {
      this.anotherPersonAdded = true;
    }
    let type =obj.mainContainerName;
    let duplicateOf =obj.duplicateOf
    const containerA = Utils.getFieldFromStructure(uiStructure, type);
    
    const containerTo = JSON.parse(
      JSON.stringify(
        Utils.getFieldFromStructure(uiStructure, duplicateOf)
      )
    );
    let index=containerA.fields[containerA.fields.length-1].index!=undefined?containerA.fields[containerA.fields.length-1].index+1:containerA.fields.length+1;
    let deleteBtn =containerTo.fields.find(x=>x.name.indexOf('delete-')>-1);
    deleteBtn.className = "_display-flex _flex-row-reverse";
    deleteBtn.name =deleteBtn.name.slice(0,-1);
    containerTo.className = "_mt-large";
    containerTo.name = duplicateOf.slice(0,-1) + index;
    containerTo.index = index;
    containerA.fields.push(containerTo);

    

    this.recursiveSearch(containerTo.fields, duplicateOf,index,uiData);
    const containerNameIndex =containerTo.fields.find(x=>x.deleteLabelChange==true);
    if(containerNameIndex!=undefined)
    containerNameIndex.value =containerNameIndex.value.slice(0,-1) + (containerA.fields.length);

  if(type =="addPersonMain"){
    this.addPersonCheckBoxes(containerA,containerTo,selectedPersonsData,index,uiData)
  }
    console.log("after rcursive", containerTo)
    this.indexList[duplicateOf]++;
  }
  
  public addPersonCheckBoxes(containerA,containerTo,selectedPersonsData,index,uiData){
    //SomeOne Else Logic
    if(containerTo.fields.find(x => x.name=="someoneElse")!=undefined){
      let copyMailingAddrs =containerTo.fields.find(x => x.name=="someoneElse").fields.find(x=>x.name.indexOf("someoneElseCheckMailing")>-1);
      if(copyMailingAddrs!=undefined && uiData['selectPerson'+ (index>2?index-1:"")]?.id!="3" && this.placeIdsList.length <2 && (selectedPersonsData.filter(data=>data.value!=='employer').length<2)){
      copyMailingAddrs.className = "_align-items-start _mt-medium";
      copyMailingAddrs.index=index-1;
      }
    }    
  }
  public deletePersonCheckBoxes(containerA,selectedPersonsData,i,uiData,uiStructure){    
    const placeidIndex = this.placeIdsList.findIndex(x=>x.name.indexOf(i)>-1);
    //this.placeIdsList.splice(placeidIndex,1);
    containerA.fields.forEach((fields: any,index) => {
      if(fields.fields.find(x => x.name.indexOf("someoneElse")>-1)){
        var mainilingField =fields.fields.find(x => x.name.indexOf("someoneElse")>-1).fields.find(x => x.name.indexOf("someoneElseCheckMailing")>-1);
        if(this.placeIdsList.find(data=>data.name===mainilingField.name)!=undefined)
        this.placeIdsList.find(data=>data.name===mainilingField.name).name = mainilingField.name.slice(0,-1) +(mainilingField.index>2?mainilingField.index-1:"");
        mainilingField.index =mainilingField.index>0?mainilingField.index-1:0;
      }
    });
  }
  
  public recursiveSearch = (obj, duplicateOf,index,uiData) => {
    console.log("before rcursive", obj)
    obj.forEach((field: any) => {
      let value = field;
      if (value.type !== "container") {
      value.name = `${value.name}${index}`;
      if (value.value){
      if(value.descriptionChange==undefined ||value.descriptionChange)
        value.value = value.value.slice(0, -1) + index;
      }
      if((value.type==="custom" || value.type==="alert") && value.containerDependsOn!==undefined) {
        if(value.displayIf){
          if(value.displayIf[0].hasOwnProperty(value.containerDependsOn)){
            let newFieldName=`${value.containerDependsOn}${index}`;        
            delete Object.assign(value.displayIf[0], {[newFieldName]: value.displayIf[0][value.containerDependsOn] })[value.containerDependsOn];
          }          
        }
        if(value.requireIf){
          if(value.requireIf[0].hasOwnProperty(value.containerDependsOn)){
            let newFieldName=`${value.containerDependsOn}${index}`;        
            delete Object.assign(value.requireIf[0], {[newFieldName]: value.requireIf[0][value.containerDependsOn] })[value.containerDependsOn];
          }
        }
      }
      if(value?.type==="datepicker" && value?.containerDependsOn!==undefined && value?.disablDependsOn!==undefined) {
        if(value?.displayIf){
          if(value?.displayIf[0]?.hasOwnProperty(value?.containerDependsOn)){
            let newFieldName=`${value?.containerDependsOn}${index}`;        
            delete Object?.assign(value?.displayIf[0], {[newFieldName]: value?.displayIf[0][value?.containerDependsOn] })[value?.containerDependsOn];
          }          
        }
        if(value?.requireIf){
          if(value?.requireIf[0]?.hasOwnProperty(value?.containerDependsOn)){
            let newFieldName=`${value?.containerDependsOn}${index}`;        
            delete Object?.assign(value?.requireIf[0], {[newFieldName]: value?.requireIf[0][value?.containerDependsOn] })[value?.containerDependsOn];
          }
        }
      }
     
        this.concateIndexValue (value,index);
        if(value.name.toLowerCase().indexOf('country')>-1){
          uiData[value.name]={
            "code": "NO",
            "name": $localize`:@@Norge:Norway`,
            "rpaValue": "NO"
          }
        }

      } else if (value.type === "container") {
        this.concateIndexValue (value,index);
        value.index=index
        value = value.fields;
        this.recursiveSearch(value, duplicateOf,index,uiData);
      }


    });
  };

  private concateIndexValue (value,index){

  if(value.displayIf!=undefined && value.displayIf.length>0){
    let key = Object.keys(value.displayIf[0]);
    key  = key.filter(x=>x!="typeDetailsSelected");
    console.log('key',key);
    if(value.containerDependsOn!=undefined&&key!=undefined){
    const dependsFields=value.containerDependsOn.split(',');
    dependsFields.forEach((element,i )=> {
      if(value.containerDependsOn!=undefined && key[i]===dependsFields[i]){
        const p = value.displayIf[0][key[i]]
        if(value.displayIf!=undefined){
        let key1 = [key[i]] + index
        delete value.displayIf[0][key[i]];
        value.displayIf[0][key1] = p;
        }
        console.log('displayIf',value.displayIf)
      }

   } )}

  } 
  if(value.requireIf!=undefined && value.requireIf.length>0){
    let key = Object.keys(value.requireIf[0]);
    key  = key.filter(x=>x!="typeDetailsSelected");
    console.log('key',key);
    if(value.containerDependsOn!=undefined&&key!=undefined){
    const dependsFields=value.containerDependsOn.split(',');
    dependsFields.forEach((element,i )=> {
      if(value.containerDependsOn!=undefined && key[i]===dependsFields[i]){
        const p = value.requireIf[0][key[i]]    
        if(value.requireIf!=undefined){
          let key1= [key[i]] + index
          delete value.requireIf[0][key[i]];
          value.requireIf[0][key1] = p;
        }

        console.log('displayIf',value.requireIf)
      }

   } )}

  } 
  if(value.disableIf!=undefined && value.disableIf.length>0){
    let key = Object.keys(value.disableIf[0]);
    key  = key.filter(x=>x!="typeDetailsSelected");
    console.log('key',key);
    if(value.disablDependsOn!=undefined&&key!=undefined){
    const dependsFields=value.disablDependsOn.split(',');
    dependsFields.forEach((element,i )=> {
      if(value.disablDependsOn!=undefined && key[i]===dependsFields[i]){
        const p = value.disableIf[0][key[i]]
        let key1 = [key[i]] + index
        delete value.disableIf[0][key[i]];
        value.disableIf[0][key1] = p;
      
        console.log('disableIf',value.displayIf)
      }

   } )}
  }
}
  public deleteContainer(name, type,uiData,uiStructure,currentSectionName,augFormService,selectedPersonsData=[]): void {
    if(selectedPersonsData[0]?.value === "employer") {
      this.anotherPersonAdded = false;
    }    
    const c = name.split("-")[1];
    const matches = c.match(/(\d+)/);
    const i = matches[0];
    const containerA = Utils.getFieldFromStructure(uiStructure, type);
    const index = containerA.fields.findIndex((x) => x.name === c);
    const dataFields=Utils.getFieldFromStructure(uiStructure, type).fields[index].fields
    const resultData= this.recursiveFunctionUIStructure(dataFields,"allFields","");
    resultData.forEach((data) => {
      delete uiData[data.name];
      delete augFormService.Form[currentSectionName].controls[
        data.name
      ];
    });
    
     
    Utils.getFieldFromStructure(uiStructure, type).fields.splice(index, 1);
    containerA.fields.forEach((fields: any,index) => {
      fields.fields.forEach((field) => {
        let value = field;
        if(value.deleteLabelChange!=undefined && value.deleteLabelChange){
          value.value = value.value.slice(0, -1) + (index + 1);
        }     
      })
    });
    const itemindex=selectedPersonsData.map(e=>e.value).indexOf("employer");
    if (type === "addPersonMain" && !(selectedPersonsData.length>1 && itemindex!=-1 && itemindex>0)) {
      this.deletePersonCheckBoxes(containerA,selectedPersonsData,i,uiData,uiStructure);
    }
    
  }
  updateBrowserTitleNordics(title): void {
    this.titleService.setTitle('CDP - ' + title);
  }
updateStepsNordics(step,stepsList,type): Step {
    const currentStepIndex = step.current.slNo;
    if(type==="nextStep"){
      if (currentStepIndex === stepsList.length) {
        step.next = undefined;
        step.prev = stepsList.length-1;
        return step;
      }
      let nextStep;
      for (const key in stepsList) {
        if (!key || !stepsList[key]) {
          continue;
        }
        if (stepsList[key].slNo === currentStepIndex + 1) {
          nextStep = stepsList[key];
          break;
        }
      }
      step.next = nextStep;
    }else if(type==="prevStep"){
      if (currentStepIndex === 0) {
        step.prev = undefined;
        return step;
      }
      let prevStep;
      for (const key in stepsList) {
        if (!key || !stepsList[key]) {
          continue;
        }
        if (stepsList[key].slNo === currentStepIndex - 1) {
          prevStep = stepsList[key];
          break;
        }
      }
      step.prev = prevStep;
    }

    return step;
  }
  setApiKeyNorcids(field,formData) {
    const apikeyChange = Utils.getFieldFromStructure(formData.uiStructure, field);
    if (apikeyChange && apikeyChange.config) {
      apikeyChange.config.apiKey = this.config.googleMapsAPIKey;
    }
    if (formData.uiData[field] && formData.uiData[field].place_id) {
      formData.uiData[field] = formData.uiData[field].place_id;
    } else  if (formData.uiData[field] && formData.uiData[field]) {
      formData.uiData[field] = formData.uiData[field];
    }
  }
  addContinueBtnData(stepList){
    
    this.nordicsApiService.lossDetails.subscribe((data)=>{
      console.log('nordicsApiService',data);
      if(data.length>0){
        stepChangeArray.tripDetails.nextContainer=data[0].containerName;
        data.length>0&&data.forEach((element,i)=>{
          if(i!=(data.length-1)){
            stepChangeArray[element.containerName]={"prevStep":null,"nextStep":null,nextContainer:data[i].containerName+element.supplementLabel,prevContainer:i==0?stepChangeArray.personalDetails.nextContainer:data[i-1].containerName + 'Expense',isClaimAmount:false};
            stepChangeArray[element.containerName+ element.supplementLabel]={"prevStep":null,"nextStep":null,nextContainer:element.containerName+'Expense',prevContainer:(i>0)?element.containerName:data[0].containerName,isClaimAmount:false}
            stepChangeArray[element.containerName+'Expense']={"prevStep":null,"nextStep":null,nextContainer:data[i+1].containerName,prevContainer:element.containerName + element.supplementLabel,isClaimAmount:false}
          }
          else if(i===(data.length-1)){
            stepChangeArray[element.containerName]={"prevStep":null,"nextStep":null,nextContainer:data[i].containerName+element.supplementLabel,prevContainer:i==0?stepChangeArray.personalDetails.nextContainer:data[i-1].containerName + 'Expense',isClaimAmount:false}
            stepChangeArray[element.containerName+ element.supplementLabel]={"prevStep":null,"nextStep":null,nextContainer:element.containerName+'Expense',prevContainer:data[i].containerName,isClaimAmount:true};
            stepChangeArray[element.containerName+'Expense']={"prevStep":null,"nextStep":FNOL_STEPS_NO_TP.UPLOAD_DOCS.name,nextContainer:"uploadDocumentDetails",prevContainer:element.containerName+ element.supplementLabel,isClaimAmount:true};
            //stepChangeArray["claimAmountDetails"]={"prevStep":null,"nextStep":FNOL_STEPS_NO_TP.UPLOAD_DOCS.name,nextContainer:"uploadDocumentDetails",prevContainer:element.containerName+ element.supplementLabel,isClaimAmount:true}

          }

        });
      }
      console.log('stepChangeArray',stepChangeArray);

    })   
  } 

  fetchTravelDoc(event,countryLob){
  
    let fileList = [];
    let ReqHead;
    
    let ReqHeadNor = [{ name: "Polisenummer, med mindre du melder krav via reiseforsikringen i ditt kredittkort" ,id:99},
    { name: "Ved krav via reiseforsikringen tilknyttet ditt kredittkort, ber vi deg opplyse de første 6- og siste 4 sifrene i kortnummeret, samt kontoutskrift som viser betaling av reisen",id:98 },
    { name: "Kontodetaljer ved eventuell erstatning" ,id:97},
    { name: "Informasjon og dokumentasjon som dokumenterer kravet (se detaljer nedenfor)" ,id:96},
    ];

    let ReqHeadEng = [{ name: "Policy number, unless claiming with coverage through your credit card" ,id:99},
    { name: "If claiming with coverage through your credit card, the first 6 and last 4 digits of your credit card number and evidence that you have paid for the trip",id:98 },
    { name: "Bank details in case of payment" ,id:97},
    { name: "Information and documentation supporting the loss that has been suffered (details below)" ,id:96},
    ];

    let  ReqHeadEngHlf = [{ name: "HLF membership number" ,id:99},
    { name: "If claiming with HLF Plus Insurance for an extension of the sum insured, your Plus Insurance policy number",id:98 },
    { name: "Bank details in case of payment" ,id:97},
    { name: "Information and documentation supporting the loss that has been suffered (details below)" ,id:96},
    ];

    let  ReqHeadNorHlf = [{ name: "HLF medlemsnummer" ,id:99},
    { name: "Dersom du har tegnet HLF PLUSS-forsikring, ber vi om ditt forsikringsnummer",id:98 },
    { name: "Kontodetaljer ved eventuell erstatning" ,id:97},
    { name: "Informasjon og dokumentasjon som dokumenterer kravet (se detaljer nedenfor)" ,id:96},
    ];

    let ReqHeadEngAH=[{name:"Policy number, unless claiming with coverage through your credit card",id:99},
    {name:"If claiming with coverage through your credit card, the first 6 and last 4 digits of your credit card number",id:98},
    {name:"Bank details in case of payment",id:97},
    {name:"Information and documentation supporting the loss that has been suffered (details below)",id:96}
    ];
    let ReqHeadEngAHCritical=[{name:"Policy number",id:99},
    {name:"Bank details in case of payment",id:97},
    {name:"Information and documentation supporting the loss that has been suffered (details below)",id:96}
    ];
    let ReqHeadNOrAH=[{name:"Polisenummer, med mindre du melder krav via reiseforsikringen tilknyttet ditt kredittkort",id:99},
    {name:"Ved krav via reiseforsikringen tilknyttet ditt kredittkort, ber vi deg opplyse de første 6- og siste 4 sifrene i kortnummeret",id:98},
    {name:"Kontodetaljer ved eventuell erstatning",id:97},
    {name:"Informasjon og dokumentasjon som dokumenterer kravet (se detaljer nedenfor)",id:96}
    ];
    let ReqHeadNOrAHCritical=[{name:"Polisenummer",id:99},
    {name:"Kontodetaljer ved eventuell erstatning",id:97},
    {name:"Informasjon og dokumentasjon som dokumenterer kravet (se detaljer nedenfor)",id:96}
    ];
    let docsArr =[];
    if (countryLob == 'en_no_tp' || countryLob == 'no_no_tp') {
      ReqHead = this.language === 'en' ? ReqHeadEng : ReqHeadNor;
      event.forEach(ele => { docsArr.push(...lossTypeDocumentsList[countryLob][ele.name]); });
      const resultData = [...new Map(docsArr.map((item) => [item["id"], item])).values()]
      this.nordicsApiService.setLosstypeDocuments(resultData);
      fileList = [ReqHead, resultData];
     
    }
    else if(countryLob == 'no_no_hlf' || countryLob == 'en_no_hlf' ){
      docsArr = lossTypeDocumentsList[countryLob][event.name];
      this.nordicsApiService.setLosstypeDocuments(docsArr);
      ReqHead = this.language === 'en' ? ReqHeadEngHlf: ReqHeadNorHlf;
      fileList = [ReqHead, docsArr];
    }
    else if(countryLob == 'no_no_ah' || countryLob == 'en_no_ah' ){
      var selectedLossType = this.dataStoreService.getSelectedData();
    const selectedLabel = this.pageLabels.filter((obj)=> {
      return obj.name == selectedLossType[0].name;
    });
      if(selectedLabel[0].heading == "Critical Illness" || selectedLabel[0].heading == "Kritisk sykdom"){
        docsArr = lossTypeDocumentsList[countryLob][event.name];
        this.nordicsApiService.setLosstypeDocuments(docsArr);
        ReqHead = this.language === 'en' ? ReqHeadEngAHCritical: ReqHeadNOrAHCritical;
        fileList = [ReqHead, docsArr];
      }
      else{
      docsArr = lossTypeDocumentsList[countryLob][event.name];
      this.nordicsApiService.setLosstypeDocuments(docsArr);
      ReqHead = this.language === 'en' ? ReqHeadEngAH: ReqHeadNOrAH;
      fileList = [ReqHead, docsArr];
      }
    } 

    return fileList;
  }


recursiveFunctionUIStructure = (fieldsArr,optionName,formData,results = []) => {
  this.uiData=formData.uiData;
    const r = results;
    fieldsArr.forEach((field) => {
      let value = field;
      if (value.type !="container") {
        switch(optionName){
          case "datepickers":
            if(value.type=="datepicker" || value.type == "timepicker")
              this.uiData[value.name]=this.uiData[value.name] ? new Date(this.uiData[value.name]):null;
            break;
             default:
              break;
        }
        r.push(value);
      } else if (value.type === "container") {
        value = value.fields;
        this.recursiveFunctionUIStructure(value,optionName,formData, r);
      }
    });
    return r;
  };
  setCopyAddressFields(e,uiData,uiStructure){
    const objData = e.form.eventInfo.eventSource;
    const controlName=objData.name;
    const matches = controlName.match(/(\d+)/);
    const fieldId = matches!=null?parseInt(matches[0]):"";
    const optionLabels = objData.optionLabels.split(',');
    const copyFields =['MailingAddress','PostalCode','City','Country'];
      if(uiData[controlName]=="yes"){
        let prevSelectPerson = Utils.getFieldFromStructure(uiStructure, objData.duplicateOf).fields[objData.index>1?objData.index - 1:0];
        if(prevSelectPerson!=undefined){
        const selectedField = uiData[e.form.eventInfo.eventSource.containerDependsOn + (objData.index >1 ? objData.index : "" )].key.split(" ").join("")
        let selectedMailAddress;
        if(selectedField.toLowerCase()==optionLabels[2].toLowerCase() && uiData["checkEmployer"+ (objData.index >1 ? objData.index : "")] =="yes"){
          selectedMailAddress = prevSelectPerson.fields.find(x=>x.name.toLowerCase().indexOf(selectedField.toLowerCase()) > -1).fields.find(z=>z.name.toLowerCase().indexOf("personemployercheck") > -1).fields.find(y=>y.name.toLowerCase().indexOf(objData.dependsOn) > -1);
        }else if(selectedField.toLowerCase()==optionLabels[1].toLowerCase() && uiData["someoneElseCheckMailing"+ (objData.index > 0 ? objData.index+1 : "")] =="yes"){
          selectedMailAddress = prevSelectPerson.fields.find(x => x.name.toLowerCase().indexOf(selectedField.toLowerCase()) == 0).fields.find(y => y.name.toLowerCase().indexOf(objData.dependsOn) > -1);
        }
        else if(selectedField.toLowerCase()==optionLabels[0].toLowerCase()){
          selectedMailAddress = prevSelectPerson.fields.find(x => x.name.toLowerCase().indexOf(selectedField.toLowerCase()) > -1).fields.find(y => y.name.toLowerCase().indexOf(objData.dependsOn) > -1);

        }

        const labelName = objData.name.split("Check")[0] + "MailingAddress";
        let placeidName="";
          placeidName=selectedMailAddress.name;
        const labelConcate =objData.name.split("Check")[0];
          copyFields.forEach(data=>{          
            uiData[labelConcate+data+fieldId]=uiData[selectedField+data];
          });
        copyFields.forEach(data=>{
          Utils.getFieldFromStructure(uiStructure, labelConcate+data+fieldId).disabled=true;
        })
        this.augFormService.detectChanges.next();
      }

      }
   else {
      const labelConcate =objData.name.split("Check")[0];
      copyFields.forEach(data=>{
        Utils.getFieldFromStructure(uiStructure, labelConcate+data+fieldId).disabled=false;
      })
   }
  }
  setMinMaxDate(uiData,uiStructure,data,sourceLabel,type,futureDate){
    data.forEach((destinationLabel)=>{
      let enddateObj = Utils.getFieldFromStructure(uiStructure, destinationLabel);
      if(enddateObj!=undefined){
      if(type=="min"){
      enddateObj.minDate =sourceLabel!=""? new Date(uiData[sourceLabel]):new Date();
      if(!futureDate)
      enddateObj.maxDate =new Date();
      }
      if(type=="max"){
      enddateObj.maxDate = sourceLabel!=""? new Date(uiData[sourceLabel]):new Date();
      }
    }
    })

  }

   prepareDeclarationObject(){
     let dataFields= this.dataStoreService.get("fnol-claimant-info").uiStructure.fields;
     let uiData=this.dataStoreService.get("fnol-claimant-info").uiData;
  let r=[]
   }
  copyLossTypeDetails(uiData, fields, copyTo, copyFrom) {
    fields.forEach(val => {
      if(uiData[`${copyFrom + val}`]!=undefined && uiData[`${copyFrom + val}`]!=null && uiData[`${copyFrom + val}`]!="" )
      uiData[`${copyTo + val}`] = uiData[`${copyFrom + val}`];
     
    });
                                                                                                                                                                                                                                                                                                                                              
  }
  getMultiselect(){​​​​​​
    return this.multiSelect;
  }​​​​​​
  setMultiselect(value){​​​​​​
     this.multiSelect  = value;
  }
  ​​​​​​
  getClaimAmountTypeStatus(lossTypeData,selectPersonDetails){
    console.log("inside singlke selection")
    let multiLossTypes;
    let claimAmountType="";
    let lossType= this.dataStoreService.get('fnol-landing').uiStructure[0].value;
    //if(lossTypeData.length == 1){
      multiLossTypes =false;
      console.log('one loss type');
      console.log(lossType,"lossType value fjfjfjjf")
       if((selectPersonDetails.find(personal=>personal.value==="employer")!==undefined) && (selectPersonDetails.find(personal=>personal.value==="yourself")!==undefined) && (selectPersonDetails.find(personal=>personal.value==="someoneElse") !==undefined ) ){
        console.log('baggage single loss with employer');
        claimAmountType = 'singleWithoutEmployer';
       }
       else if((lossType != "Baggage delay / Loss of checked baggage" || lossType !="Forsinket / tap av innsjekket bagasje") && (selectPersonDetails.find(personal=>personal.value==="employer")!==undefined) &&(selectPersonDetails.find(personal=>personal.value==="yourself")!==undefined || selectPersonDetails.find(personal=>personal.value==="someoneElse"))  ){
        console.log('baggage single loss with employer');
        claimAmountType ='singleWithEmployer';   
      }
       else if((lossType == "Baggage delay / Loss of checked baggage" || lossType =="Forsinket / tap av innsjekket bagasje") && (selectPersonDetails.find(personal=>personal.value==="yourself")!==undefined && selectPersonDetails.find(personal=>personal.value==="someoneElse")!==undefined || selectPersonDetails.find(personal=>personal.value==="yourself")!==undefined && selectPersonDetails.find(personal=>personal.value==="someoneElse")!==undefined && selectPersonDetails.find(personal=>personal.value==="employer")!==undefined || (selectPersonDetails.find(personal=>personal.value==="employer")!==undefined) &&(selectPersonDetails.find(personal=>personal.value==="yourself")!==undefined || selectPersonDetails.find(personal=>personal.value==="someoneElse") ) )){
         console.log('baggage delay any combination');
         claimAmountType ='singleWithEmployer';
      }
       else {
        console.log('singleBaggageLossWithoutEmployer');
        claimAmountType ='singleWithoutEmployer';
       }
    // }else{
    //   console.log('multiple loss type');
    //   multiLossTypes=true;
    //  }
     return {status:multiLossTypes,type:claimAmountType};
  }
  getMultiLossForClaimAmount(selectPersonDetails){
    let claimAmountType="";
    let lossType= this.dataStoreService.get('fnol-landing').uiStructure[0].value;
     if((selectPersonDetails.find(personal=>personal.value==="employer")!==undefined) && (selectPersonDetails.find(personal=>personal.value==="yourself")!==undefined) && (selectPersonDetails.find(personal=>personal.value==="someoneElse") !==undefined ) ){
        console.log('baggage multi loss with employer');
        claimAmountType = 'singleWithoutEmployer';
       }
       else if((lossType != "Baggage delay / Loss of checked baggage" || lossType !="Forsinket / tap av innsjekket bagasje") && (selectPersonDetails.find(personal=>personal.value==="employer")!==undefined) &&(selectPersonDetails.find(personal=>personal.value==="yourself")!==undefined || selectPersonDetails.find(personal=>personal.value==="someoneElse"))  ){
        console.log('baggage single loss with employer');
        claimAmountType ='singleWithEmployer';     
      }
       else if((lossType == "Baggage delay / Loss of checked baggage" || lossType =="Forsinket / tap av innsjekket bagasje") && (selectPersonDetails.find(personal=>personal.value==="yourself")!==undefined && selectPersonDetails.find(personal=>personal.value==="someoneElse")!==undefined || selectPersonDetails.find(personal=>personal.value==="yourself")!==undefined && selectPersonDetails.find(personal=>personal.value==="someoneElse")!==undefined && selectPersonDetails.find(personal=>personal.value==="employer")!==undefined || (selectPersonDetails.find(personal=>personal.value==="employer")!==undefined) &&(selectPersonDetails.find(personal=>personal.value==="yourself")!==undefined || selectPersonDetails.find(personal=>personal.value==="someoneElse") ) )){
         console.log('baggage delay any combination');
         claimAmountType ='singleWithEmployer';
      }
       else{
        console.log('baggage multi loss without employer');
        claimAmountType = 'singleWithoutEmployer';
       }
       return  claimAmountType;
  }

  matchAccountNo(controlName: any, uiData: {}, augFormService: any,currentSectionName) {
      if(controlName === "confirmAccountNumberN"||controlName ==="accountNumberN"){
        if(uiData["confirmAccountNumberN"] !== uiData["accountNumberN"]){
          augFormService.Form[currentSectionName]
          .get("confirmAccountNumberN")
          .setErrors({ custom: { errorMessage:this.language === 'en' ? "Your account number must match":"Dine kontonummer må være identiske" } });
      }else{
          augFormService.Form[currentSectionName]
            .get("confirmAccountNumberN")
            .setErrors(null);
        }


       }
       if(controlName === "confirmAccountNumber"||controlName ==="accountNumber"){
        if(uiData["confirmAccountNumber"] !== uiData["accountNumber"]){
          augFormService.Form[currentSectionName]
          .get("confirmAccountNumber")
          .setErrors({ custom: { errorMessage:this.language === 'en' ? "Your account number must match":"Dine kontonummer må være identiske" } });
      }else{
          augFormService.Form[currentSectionName]
            .get("confirmAccountNumber")
            .setErrors(null);
        }
        

       }

       if(controlName === "confirmAccountNumberN2"||controlName ==="accountNumberN2"){
        if(uiData["confirmAccountNumberN2"] !== uiData["accountNumberN2"]){
          augFormService.Form[currentSectionName]
          .get("confirmAccountNumberN2")
          .setErrors({ custom: { errorMessage:this.language === 'en' ? "Your account number must match":"Dine kontonummer må være identiske" } });
      }else{
          augFormService.Form[currentSectionName]
            .get("confirmAccountNumberN2")
            .setErrors(null);
        }

       }
       if(controlName === "confirmAccountNumberF"||controlName ==="accountNumberF"){
        if(uiData["confirmAccountNumberF"] !== uiData["accountNumberF"]){
          augFormService.Form[currentSectionName]
          .get("confirmAccountNumberF")
          .setErrors({ custom: { errorMessage:this.language === 'en' ? "Your account number must match":"Dine kontonummer må være identiske" } });
      }else{
          augFormService.Form[currentSectionName]
            .get("confirmAccountNumberF")
            .setErrors(null);
        }

       }
       if(controlName === "confirmAccountNumberF2"||controlName ==="accountNumberF2"){
        if(uiData["confirmAccountNumberF2"] !== uiData["accountNumberF2"]){
          augFormService.Form[currentSectionName]
          .get("confirmAccountNumberF2")
          .setErrors({ custom: { errorMessage:this.language === 'en' ? "Your account number must match":"Dine kontonummer må være identiske" } });
      }else{
          augFormService.Form[currentSectionName]
            .get("confirmAccountNumberF2")
            .setErrors(null);
        }

       }



  }
getPaymentScreenStatus(uiData,selectPersonDetails){
  let getsomeElseCount=true;
  let isPaymentDuplicate=false;
  let copyMailingAddress=[];
  const someElseCount = selectPersonDetails.filter(obj=>obj.value ==="someoneElse").length
  const employerCheck = selectPersonDetails.some(obj=>obj.value === "employer")
  const yourselfCheck = selectPersonDetails.some(obj=>obj.value === "yourself")
  const someOneElseCheck = selectPersonDetails.some(obj=>obj.value === "someoneElse")



  if( !(yourselfCheck && someOneElseCheck)){
   if(someElseCount < 1 && yourselfCheck && !(employerCheck && (uiData['checkEmployer'] == 'yes')) ){
     copyMailingAddress =[]
     const someOneVal =selectPersonDetails.find(i=>i.value =="yourself")
     const getindex = someOneVal?.name.split("selectPerson")[1]
     if(uiData["yourselfMailingAddress" + getindex + "val"]!=undefined){
       copyMailingAddress.push(uiData["yourselfMailingAddress" + getindex + "val"]);
     }else{
       copyMailingAddress.push(uiData["yourselfMailingAddress" + getindex]);
   }
     copyMailingAddress.push(
       uiData["yourselfPostalCode"+ getindex],
       uiData["yourselfCity"+ getindex],
       uiData["yourselfCountry"+ getindex],
     );
     getsomeElseCount =true;
   }else if(someElseCount < 2 && someOneElseCheck && !yourselfCheck && !(employerCheck && (uiData['checkEmployer']=='yes'))){
     copyMailingAddress =[]
     const someOneVal =selectPersonDetails.find(i=>i.value =="someoneElse")
     const getindex = someOneVal?.name.split("selectPerson")[1]
     if(uiData["someoneElseMailingAddress" + getindex + "val"]!=undefined){
       copyMailingAddress.push(uiData["someoneElseMailingAddress" + getindex + "val"]);
     }else{
       copyMailingAddress.push(uiData["someoneElseMailingAddress" + getindex]);
   }
     copyMailingAddress.push(
       uiData["someoneElsePostalCode"+ getindex],
       uiData["someoneElseCity"+ getindex],
       uiData["someoneElseCountry"+ getindex],
     )
     getsomeElseCount =true;

   } else if((employerCheck && (uiData['checkEmployer'] =='yes') ) && !yourselfCheck && !someOneElseCheck){
     copyMailingAddress =[]
     if(uiData["employerMailingAddress" +"val"]!=undefined){
       copyMailingAddress.push(uiData["employerMailingAddress" +"val"]);
     }else{
       copyMailingAddress.push(uiData["employerMailingAddress"]);
   }
       copyMailingAddress.push(
         uiData["employerPostalCode"],
         uiData["employerCity"],
         uiData["employerCountry"],
       )
       getsomeElseCount =true;
   }
   else{
     getsomeElseCount =false;
     console.log("dont show Check")
    }

 }else{
   getsomeElseCount =false;
   console.log("dont show Check")
  }
   const yourself = selectPersonDetails.some(obj=>obj.value === "yourself")
   const employer = selectPersonDetails.some(obj=>obj.value === "employer")
   const someElse = selectPersonDetails.some(obj=>obj.value ==="someoneElse")
   if((yourself && employer )||(someElse && employer)){
     isPaymentDuplicate =true;
   }else{
     isPaymentDuplicate =false;

   }
   return {getsomeElseCount,isPaymentDuplicate,copyMailingAddress};

}
getPaymentScreenStatusHLF(uiData, selectPersonDetails, type) {
  let getsomeElseCount = true;
  let isPaymentDuplicate = false;
  let copyMailingAddress = [];
  const someElseCount = selectPersonDetails.filter(obj => obj.value === "someoneElse").length
  const employerCheck = selectPersonDetails.some(obj => obj.value === "employer")
  const yourselfCheck = selectPersonDetails.some(obj => obj.value === "yourself")
  const someOneElseCheck = selectPersonDetails.some(obj => obj.value === "someoneElse")
  if (type === "Lost" || type === "Broken / Damaged" || type ==="Stolen") {
    if (yourselfCheck) {
      const someOneVal = selectPersonDetails.find(i => i.value == "yourself")
      const getindex = someOneVal?.name.split("selectPerson")[1]
      if (uiData["yourselfMailingAddress" + getindex + "val"] != undefined) {
        copyMailingAddress.push(uiData["yourselfMailingAddress" + getindex + "val"]);
      } else {
        copyMailingAddress.push(uiData["yourselfMailingAddress" + getindex]);
      }
      copyMailingAddress.push(
        uiData["yourselfPostalCode" + getindex],
        uiData["yourselfCity" + getindex],
        uiData["yourselfCountry" + getindex],
      );
    } else if (someOneElseCheck) {
      const someOneVal = selectPersonDetails.find(i => i.value == "someoneElse")
      const getindex = someOneVal?.name.split("selectPerson")[1]
      if (uiData["someoneElseMailingAddress" + getindex + "val"] != undefined) {
        copyMailingAddress.push(uiData["someoneElseMailingAddress" + getindex + "val"]);
      } else {
        copyMailingAddress.push(uiData["someoneElseMailingAddress" + getindex]);
      }
      copyMailingAddress.push(
        uiData["someoneElsePostalCode" + getindex],
        uiData["someoneElseCity" + getindex],
        uiData["someoneElseCountry" + getindex],
      )
    } 
    
  }

  return copyMailingAddress;
}
  getPaymentScreenStatusAH(uiData, selectPersonDetails, type) {
    let getsomeElseCount = true;
    let isPaymentDuplicate = false;
    let copyMailingAddress = [];
    const someElseCount = selectPersonDetails.filter(obj => obj.value === "someoneElse").length
    const employerCheck = selectPersonDetails.some(obj => obj.value === "employer")
    const yourselfCheck = selectPersonDetails.some(obj => obj.value === "yourself")
    const someOneElseCheck = selectPersonDetails.some(obj => obj.value === "someoneElse")
    if (type === "Personal Accident" || type === "Critical Illness") {
      if (yourselfCheck) {
        const someOneVal = selectPersonDetails.find(i => i.value == "yourself")
        const getindex = someOneVal?.name.split("selectPerson")[1]
        if (uiData["yourselfMailingAddress" + getindex + "val"] != undefined) {
          copyMailingAddress.push(uiData["yourselfMailingAddress" + getindex + "val"]);
        } else {
          copyMailingAddress.push(uiData["yourselfMailingAddress" + getindex]);
        }
        copyMailingAddress.push(
          uiData["yourselfPostalCode" + getindex],
          uiData["yourselfCity" + getindex],
          uiData["yourselfCountry" + getindex],
        );
      } else if (someOneElseCheck) {
        const someOneVal = selectPersonDetails.find(i => i.value == "someoneElse")
        const getindex = someOneVal?.name.split("selectPerson")[1]
        if (uiData["someoneElseMailingAddress" + getindex + "val"] != undefined) {
          copyMailingAddress.push(uiData["someoneElseMailingAddress" + getindex + "val"]);
        } else {
          copyMailingAddress.push(uiData["someoneElseMailingAddress" + getindex]);
        }
        copyMailingAddress.push(
          uiData["someoneElsePostalCode" + getindex],
          uiData["someoneElseCity" + getindex],
          uiData["someoneElseCountry" + getindex],
        )
      } else if (employerCheck) {
        if (uiData["employerMailingAddress" + "val"] != undefined) {
          copyMailingAddress.push(uiData["employerMailingAddress" + "val"]);
        } else {
          copyMailingAddress.push(uiData["employerMailingAddress"]);
        }
        copyMailingAddress.push(
          uiData["employerPostalCode"],
          uiData["employerCity"],
          uiData["employerCountry"],
        )
      }
    } else {
      if (uiData["accidentalMailingAddress" + "val"] != undefined) {
        copyMailingAddress.push(uiData["accidentalMailingAddress" + "val"]);
      } else {
        copyMailingAddress.push(uiData["accidentalMailingAddress"]);
      }
      copyMailingAddress.push(
        uiData["accidentalPostalCode"],
        uiData["accidentalCity"],
        uiData["accidentalCountry"],
      )

    }

    return copyMailingAddress;
  }
}
