import { IsubmitFeedbackModel } from './../shared/model/submit-feedback-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { throwError as observableThrowError, BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { LoadingService } from "axis-components";
import { catchError, map } from 'rxjs/operators';
import { FNOL_COUNTRY, FNOL_STEPS_JP } from '../config/fnol-constants';
import { Configuration } from '../../../../app/shared/configuration';
import { URL_PARAMETERS } from '../config/urlParameters.constant';
import { IUploadDocumentReqModel } from './../shared/model/upload-document.model';
import * as CryptoJS from 'crypto-js';
import { FnolModelService } from './fnol-model.service';
import { RegistrationConstantJP } from '../shared/model/registration-constants-JP';
import { submitClaim } from '../shared/model/submit-claim-nordics';
import { addPolicyModel } from '../shared/model/add-policy-model';
import { apiEndPoints } from 'src/app/shared/services/utils/apiEndPointConstants';
import { CacheService } from 'src/app/shared/services/cache.service'
import * as moment from 'moment';
import { GetUserData, updateUserData } from 'src/app/shared/services/utils/getuserdata';
import { DataStoreService } from './data-store.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { commonSetFnolSgModel } from '../fnol-sg/fnol-sg-model';
import { FnolNordicsDeclarationService } from '../fnol-nordics/service/common-nordics-declaration-services';
import { DeclarationServiceNoAhService } from '../fnol-nordics/fnol-nordics-no-ah/services/declaration-service-no-ah.service';
import { DeclarationServiceNoHlfService } from '../fnol-nordics/fnol-nordics-no-hlf/services/declaration-service-no-hlf.service';

@Injectable({
  providedIn: "root",
})
export class FnolService {
  public pageDetail = new BehaviorSubject<object>({ step: "", age: "" });
  public copyAccount = new BehaviorSubject<any>({});
  public claimType = new BehaviorSubject<object>({ type: "" });
  public setClaim = new BehaviorSubject<object>({});
  public claimDetails = new BehaviorSubject<object>({ item: "" });
  public namEstimated = new BehaviorSubject<object>({
    lossCurrencyType: "",
    lossCurrencyCargo: "",
    cargo: "",
    loss: "",
  });
  public openPopUpEvent = new BehaviorSubject<Boolean>(false);
  public continueClickEvent = new BehaviorSubject<object>({
    stepNo: null,
    isContinueBtnClicked: false,
  });
  public setRequire=new BehaviorSubject<Boolean>(false);
  public openFromPolicyDashBoard=new BehaviorSubject<Boolean>(false);
  public upload = new BehaviorSubject<object>({ upload: "" });
  public errorEvent = new Subject<String>();
  public manualPolicyError=new BehaviorSubject(false);
  headerTransactionId: string = "";
  counterFromDashboarForSavedClaim: any;
  feedbackRequestData;
  updateDocTypeRequest = [];
  userid;
  baseUrl = "assets/data/locale";
  policyDetails: any;
  phoneCodeList: any;
  statusApp: any;
  countryKey: any;
  maintanenceApiChecked = false;
  isPolicyValidated: boolean;
  claimantAllUIData: any;
  claimtypeAllUIData: any;
  claimTypeAllStructureData: any;
  saveCounter: boolean = false;
  countFlag: boolean = true;
  setSectionName: any;
  savedClaimantDetails: any;
  savedDraft: any = [];
  paymentAllUIData: any;
  UploadData: any;
  Step2UIStructure: any;
  gridAvailable: any;
  savedClaimTypeDetails: any;
  savedClaimTypeStructureDetails: any;
  uploadDetails: any = {};
  claimBenifitSelected: any;
  parentNumberForSaveDraft: any;
  paymentdetails: any = {};
  clickedFromDashboard: boolean = true;
  showPolicyPopUP: boolean = true;
  SelectedType: any;
  FollowUpForSavedDraft: any = false;
  savedDOO: any;
  constructor(
    @Inject(OKTA_AUTH)
    public oktaAuth: OktaAuth,
    private http: HttpClient,
    private config: Configuration,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private router: Router,
    private dataStoreService: DataStoreService,
    private cacheService: CacheService,
    private nordics:FnolNordicsDeclarationService,
    public fnolNordicsDeclarationService: DeclarationServiceNoAhService,
    public fnolNordicsDeclarationHLFService:DeclarationServiceNoHlfService,
  ) {
    this.config.maintanenceSubject.subscribe(res => {
      if (res === 'checkMaintanenceApiStatus') {
        this.pageRouting();
      }

    });
  }
  oneTimeCAll
  setUrlParameters(): void {
    URL_PARAMETERS.COUNTRY = this.route.snapshot.queryParamMap.get("country") || this.cacheService.get('country').toUpperCase();
    URL_PARAMETERS.LANGUAGE = this.route.snapshot.queryParamMap.get("language");
    URL_PARAMETERS.POLICY_NO =
      this.route.snapshot.queryParamMap.get("policyNo");
    URL_PARAMETERS.LOB = this.route.snapshot.queryParamMap.get("lob");
    URL_PARAMETERS.PARTNER = this.route.snapshot.queryParamMap.get("partner");
    URL_PARAMETERS.TYPE = this.route.snapshot.queryParamMap.get("type");
    URL_PARAMETERS.CAPTCHA = this.route.snapshot.queryParamMap.get("skipCaptcha");
    if (URL_PARAMETERS.COUNTRY && URL_PARAMETERS.COUNTRY.toLowerCase() !== "jp") {
      if (
        !URL_PARAMETERS.COUNTRY ||
        !URL_PARAMETERS.LANGUAGE ||
        !URL_PARAMETERS.LOB
      ) {
        this.router.navigate(["cdp/error"]);
      }
    }
  }

  getSavedDOO() {
    return this.savedDOO;
  }

  getClaimBenifitSelected() {
    return this.claimBenifitSelected;
  }

  getParentNumberForSaveDraft() {
    return this.parentNumberForSaveDraft;
  }

  getFollowUpForSavedDraft() {
    return this.FollowUpForSavedDraft;
  }

  getSelectedType() {
    return this.SelectedType;
  }

  getSavedClaimtypeDetails() {
    return this.savedClaimTypeDetails;
  }

  getSavedClaimTypeStructureDetails() {
    return this.savedClaimTypeStructureDetails;
  }

  getUploadDetails() {
    return this.uploadDetails;
  }

  getpaymentdetails() {
    return this.paymentdetails;
  }

  getStep2UIStructure() {
    return this.Step2UIStructure;
  }

  getGridAvailable() {
    return this.gridAvailable;
  }

  getpaymentAllUIData() {
    return this.paymentAllUIData;
  }

  getUploadData(){
    return this.UploadData;
  }

  getSavedDraft() {
    return this.savedDraft;
  }

  getCounterFromDashboarForSavedClaim(){
    return this.counterFromDashboarForSavedClaim;
  }

  setUrlParametersSingapore(): void {
    URL_PARAMETERS.COUNTRY = this.route.snapshot.queryParamMap.get("country");
    URL_PARAMETERS.POLICY_NO =
      this.route.snapshot.queryParamMap.get("policyNo");
    URL_PARAMETERS.LANGUAGE = this.route.snapshot.queryParamMap.get("language");
    URL_PARAMETERS.LOB = this.route.snapshot.queryParamMap.get("lob");
    URL_PARAMETERS.CAPTCHA =
      this.route.snapshot.queryParamMap.get("skipCaptcha");
  }

  getSavedClaimantDetails() {
    return this.savedClaimantDetails;
  }

  set(keys, value) {
    const encrypted = CryptoJS.AES.encrypt(value, keys);

    return encrypted.toString();
  }

  getSetSectionName() {
    return this.setSectionName;
  }

  getSaveCounter() {
    return this.saveCounter;
  }

  setSaveCounter(val: boolean){
    this.saveCounter=val
  }

  getcountFlag(){
    return this.countFlag;
  }

  clearTheValues() {
    this.savedDOO = null;
    this.claimantAllUIData = null;
    this.claimTypeAllStructureData = null;
    this.savedClaimTypeDetails = null;
    this.claimtypeAllUIData = null;
    this.UploadData = null;
    this.paymentAllUIData = null;
    this.clickedFromDashboard = false;
    this.saveCounter = false;
    this.gridAvailable = [];
  }

  //The get method is use for decrypt the value.
  get(keys, value) {
    const decrypted = CryptoJS.AES.decrypt(value, keys);

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  getAUGJson(fileName: string): Observable<any> {
    let countryUrl: string;
    //   countryUrl = this.baseUrl + '/en-sg/' + fileName + '.json';
    let country =
      URL_PARAMETERS.COUNTRY.toLowerCase() || localStorage.getItem("country");
    switch (country) {
      case FNOL_COUNTRY.AUSTRALIA.countryCode:
        if (URL_PARAMETERS.TYPE === "CAT") {
          countryUrl = this.baseUrl + "/en-au-cat/" + fileName + ".json";
        } else {
          countryUrl = this.baseUrl + "/en-au/" + fileName + ".json";
        }
       break;
      case FNOL_COUNTRY.NAM.countryCode:
        countryUrl = this.baseUrl + "/en-us/" + fileName + ".json";
        break;

      case FNOL_COUNTRY.NEWZEALAND.countryCode:
        if (URL_PARAMETERS.TYPE === "CAT") {
          countryUrl = this.baseUrl + "/en-nz-cat/" + fileName + ".json";
        } else {
          countryUrl = this.baseUrl + "/en-nz/" + fileName + ".json";
        }

       break;

      case FNOL_COUNTRY.SINGAPORE.countryCode:
        countryUrl = this.baseUrl + "/en-sg/" + fileName + ".json";
        break;

      case FNOL_COUNTRY.SINGAPORENEW.countryCode:
        countryUrl = this.baseUrl + "/en-singapore/" + fileName + ".json";
        break;

      case FNOL_COUNTRY.VIETNAM.countryCode:
        if (URL_PARAMETERS.LANGUAGE === FNOL_COUNTRY.VIETNAM.language) {
          countryUrl = this.baseUrl + "/vn-vn/" + fileName + ".json";
        } else {
          countryUrl = this.baseUrl + "/en-vn/" + fileName + ".json";
        }
        break;

      case FNOL_COUNTRY.THAILAND.countryCode:
        if (URL_PARAMETERS.LANGUAGE === FNOL_COUNTRY.THAILAND.language) {
          countryUrl = this.baseUrl + "/th-th/" + fileName + ".json";
        } else {
          countryUrl = this.baseUrl + "/en-th/" + fileName + ".json";
        }
        break;
      case FNOL_COUNTRY.JAPAN.countryCode:
          countryUrl = this.baseUrl + "/ja-ja/" + fileName + ".json";
       
        break;

        default:
          countryUrl=fileName + ".json";

      }
    return this.http.get(countryUrl);
  }
  getProductName(policy) {
    if (policy?.productInfo && this.cacheService.get("language")) {
      if (this.cacheService.get("language").toLowerCase() === "jp") {
        return policy?.productInfo?.productName_jp
          ? policy?.productInfo?.productName_jp
          : "";
      } else {
        return policy?.productInfo?.productName_en
          ? policy?.productInfo?.productName_en
          : "";
      }
    } else {
      return policy && policy?.productInfo ? policy?.productInfo?.productName_en : '';
    }
  }
  makeAddPolicyModel(selectedRows): any {
    let selectedRowsModified=[]
    selectedRows.forEach((row) => {
      let policyObj={policy:{}}

        policyObj["policy"]["emailId"] = this.cacheService.getUserId();
     // let policyData=JSON.parse(JSON.stringify(row));
      policyObj["policy"]["policyDetailsJson"] = row;

      policyObj["policy"]["statusCd"] = "Active";
      policyObj["policy"]["countryCd"] = "JP";
      delete  policyObj["policy"]['policyDetailsJson']['selected'];
      selectedRowsModified.push(policyObj)
    });
    return selectedRowsModified;
  }
  updateDoc(docObj): Observable<any> {
    //const requestBody = docobj;
    const options = this.createHeadersJP();
    const fetchClaimDetailURL =
      this.config.baseURLJP + apiEndPoints.updateDocUpload;
    return this.makeHttpPostCall(fetchClaimDetailURL, docObj, options);
  }
  deleteDoc(docObj): Observable<any> {
    const requestBody = JSON.stringify(docObj);
    const options = this.createHeadersJP();
    const fetchClaimDetailURL =
      this.config.baseURLJP + apiEndPoints.deleteDocUpload;
    return this.makeHttpPostCall(fetchClaimDetailURL, requestBody, options);
  }
  public requestJP(request, policyDetails) {
    let step1= this.dataStoreService.get("registration0").uiData;
    let step2 = this.dataStoreService.get("registration1").uiData;
    // let step3 = this.dataStoreService.get("registration2").uiData;
    let {insuredMail} =step1;
    let { lastNameKanjiClaim, firstNameKanjiClaim, lastNameKatakanaClaim,
      firstNameKatakanaClaim, postalCode, prefecture, city, condominium, phone, mobile } = step2;
    // let { hasPolicyNumber } = step3

    let registrationRequest = JSON.parse(JSON.stringify(RegistrationConstantJP))
    registrationRequest.profile.emailId = insuredMail
    registrationRequest.profile["lastNameKanji"] = lastNameKanjiClaim;
    registrationRequest.profile["firstNameKanji"] = firstNameKanjiClaim;
    registrationRequest.profile["lastNameKatakana"] = lastNameKatakanaClaim;
    registrationRequest.profile["firstNameKatakana"] = firstNameKatakanaClaim;
    registrationRequest.profile["phoneNumber"] = phone;
    registrationRequest.profile["mobileNumber"] = mobile;
    registrationRequest.profile["zipCode"] = step2.postalCode;
    registrationRequest.profile["prefecture"] = prefecture['name'];
    registrationRequest.profile["city"] = city;
    registrationRequest.profile["addressline1"] = condominium;
    // if (hasPolicyNumber === "No") {
      // registrationRequest.skipUserPolicy = 'Y';
      // delete registrationRequest.policy;
    // } else {
      registrationRequest.skipUserPolicy = 'N';
      registrationRequest.policy.emailId = insuredMail;
      registrationRequest.policy.policyDetailsJson = policyDetails
    // }
    console.log(registrationRequest)
    return registrationRequest;
  }

  public finalSubmitRegistrationJP(request, policyDetails) {
    const requestObj = JSON.stringify(this.requestJP(request, policyDetails));
    const options = this.createHeadersJP();

    const submitRegistrationURL =
      this.config.baseURLJP + apiEndPoints.submitRegistrationJP;
    return this.makeHttpPostCall(
      submitRegistrationURL,
      requestObj,
      options
    );
  }

  docUploadRequestJP(req): Observable<any> {
    const requestObj = JSON.stringify(req);
    const options = this.createHeadersJP();
    const unrDocUploadURL = this.config.baseURLJP + apiEndPoints.uploadDocJP;
    return this.makeHttpPostCall(unrDocUploadURL, requestObj, options);
    // return this.http.get('../../../assets/upload-doc.json');
  }

  public verifyPolicyJP(req: any) {
    //  const requestObj = JSON.stringify(req);
    const options = this.createHeadersJP();
    let searchNonPolicyURL = this.config.baseURLJP + apiEndPoints.searchNonAuthPolicyJP + '?' + 'accountType=P'
      + '&' + 'policyNo=' + req.policyNumber + '&' + 'insuredName=' + req.insuredName + '&' + 'birthDate=' + req.birthDate + '&' + 'countryCd=JP';

    return this.http.get(searchNonPolicyURL);

    // let requestBody = { ...request };
    // const requestObj = JSON.stringify(RegistrationConstantJP);
    // const options = this.createHeadersJP();

    // const submitRegistrationURL =
    //   this.config.baseURLJP + apiEndPoints.searchNonAuthPolicyJP;
    // return this.makeHttpPostCall(submitRegistrationURL, requestBody, options);
  }

  public fetchClaimDetail(claimObj, savedClaim?): Observable<any> {
    const IfsavedClaim = savedClaim ? savedClaim : "";
    const requestBody = JSON.stringify(claimObj);
    const options = this.createHeadersJP();
    const fetchClaimDetailURL =
      this.config.baseURLJP + apiEndPoints.fetchClaimDetail + URL_PARAMETERS.COUNTRY + IfsavedClaim;
    return this.http.get(fetchClaimDetailURL);
  }

  public deleteClaimDetail(deleteClaimObj): Observable<any> {
    const requestBody = JSON.stringify(deleteClaimObj);
    const options = this.createHeadersJP();
    const deleteClaimDetailURL =
      this.config.baseURLJP + apiEndPoints.deleteClaimDetail;
    return this.makeHttpPostCall(deleteClaimDetailURL, requestBody, options);
  }

  public addClaimDetail(addClaimRequestModel): Observable<any> {
    const requestBody = JSON.stringify(addClaimRequestModel);
    const options = this.createHeadersJP();
    const addClaimDetailURL =
      this.config.baseURLJP + apiEndPoints.addClaimDetail;
    return this.makeHttpPostCall(addClaimDetailURL, requestBody, options);
  }

  public fetchPolicies(policyObj): Observable<any> {
    const requestBody = JSON.stringify(policyObj);
    const options = this.createHeadersJP();
    const fetchPoliciesURL =
      this.config.baseURLJP + apiEndPoints.fetchPolicies + this.cacheService.getUserId();
    return this.http.get(fetchPoliciesURL);
  }

  setAppStatus(response){
    this.statusApp =response
  }

  getAppStatus(){
   return  this.statusApp
  }

  getCountry(){
    return  this.countryKey
   }

   setCountry(response){
     this.countryKey = response
   }

   getPagestatus(){
    const pagestat = window.location.pathname.split("/")[3]
    if((pagestat === "unavailable") || (pagestat === "undermaintenance")){
      return true
    }else{
      return false
    }
  }


  pageRouting(): void {
    if (this.maintanenceApiChecked) {
      return;
    }
    const country = this.getCountry()?.toUpperCase()
    this.loadingService.start("")
    if (this.config.authorizationKey_new !== undefined && this.config.userEmailID !== undefined) {
      console.log('Maintanence API About to check');
      this.maintanenceApiChecked = true
      this.appStatus(country).subscribe((responseobj) => {
        console.log('Maintanence API check finished');
        // responseobj.response[0].configValue = "AVAILABLE"
        //  responseobj.response[0].configValue = "MAINTENANCE"
        console.log('Maintanence API check finished');
        const res = responseobj.response[0]
        this.setAppStatus(res);
        // localStorage.setItem('response', JSON.stringify(res))
        // this.pageStatus.next({configValue:res.configValue})
        if(responseobj.response.length !== 0){
          localStorage.setItem('response', window.location.href)
          if (res.configValue === "DOWNTIME") {
           if(window.location.pathname.includes('nordics')){
            this.router.navigate(["/cdp/nordics",window.location.pathname.split('/')[3],window.location.pathname.split('/')[4],"unavailable"]);
           } else {
            this.router.navigate(["/cdp",res.countryCode.toLowerCase(),"unavailable"]);
           }
           
          } else if (res.configValue === "MAINTENANCE") {
            if(window.location.pathname.includes('nordics')){
              this.router.navigate(["/cdp/nordics",window.location.pathname.split('/')[3],window.location.pathname.split('/')[4],"undermaintenance"]);
             } else {
              this.router.navigate(["/cdp",res.countryCode.toLowerCase(),"undermaintenance"]);
             }
        
          }
        }
        this.loadingService.stop()

      }, (err) => {
          if(window.location.pathname.includes('nordics')){
              this.router.navigate(["/cdp/nordics",window.location.pathname.split('/')[3],window.location.pathname.split('/')[4],"unavailable"]);
           } else {
              this.router.navigate(["/cdp",country.toLowerCase(),"unavailable"]);
         }
        this.loadingService.stop()
      }
      )
    }

    this.loadingService.stop()

  }

  public appStatus(countryCode): Observable<any> {
    let fetchPoliciesURL = this.config.baseURLJP + apiEndPoints.appStatus + countryCode
    return this.http.get(fetchPoliciesURL);
  }


  public addPolicy(addPolicyRequestModel): Observable<any> {
    const requestBody = JSON.stringify(addPolicyRequestModel);
    const options = this.createHeadersJP();
    const fetchPoliciesURL = this.config.baseURLJP + apiEndPoints.addPolicy;
    return this.makeHttpPostCall(fetchPoliciesURL, requestBody, options);
  }
  updateAnalyticsData(name,event)
  {
   window['digitalData']['page']['pageInfo']['cdpPname'] = name;
   window['digitalData']['page']['pageInfo']['cdpEvent'] =event;
  }
  updateAnalyticsDataNordics(name)
  {
      
    window['digitalData'] = 
    { "site": { "name": "Claims Digital Portal", "country": window.location.pathname.split('/')[3].toUpperCase(), "language":window.location.pathname.split('/')[4].toUpperCase(), "lob":window.location.pathname.split('/')[5].toUpperCase() }, 
     "page": { "name": name, "claimtype": this.dataStoreService.get('fnol-landing') ?this.dataStoreService.get('fnol-landing').uiStructure[0].value:""}
    }
    console.log(window['digitalData'],"fnol services log")
  }
  public deletePolicy(policyNumber): Observable<any> {
    // const requestBody = JSON.stringify(policyNumber);
    const options = this.createHeadersJP();
    const deletePolicyURL = this.config.baseURLJP + apiEndPoints.addPolicy + '/' + policyNumber;
    return this.http.delete(deletePolicyURL, { headers: options });
  }

  setFeedbackRequestData(feedbackReqData): void {
    this.feedbackRequestData = feedbackReqData;
  }
  getUpdateDocTypeRequest(uploadedFiles) {
    const req = [];
    let country = URL_PARAMETERS.COUNTRY.toLowerCase() || localStorage.getItem("country") || window.location.pathname.split("/")[3]
    uploadedFiles.forEach((upload) => {
      if (country === FNOL_COUNTRY.JAPAN.countryCode) {
        let mergereq = {
          ...upload.uploadReqObj,
          ...{
            cmDocType: upload.claimBenefitType,
            userId: this.cacheService.getUserId(),
            documentName: upload.name,
            documentId: upload.docId,
            documentClassification:upload.supportDocType?.code,
            documentClassificationName:upload.supportDocType?.name,
          },
        };
        req.push(mergereq);
      } else {
        let mergereqNonJpn = {
          ...upload.uploadReqObj,
          ...{
            cmDocType: upload.claimBenefitType?upload.claimBenefitType:"FNOL - Internet Report",
            userId: this.cacheService.getUserId() ? this.cacheService.getUserId() : this.config.userEmailID,
            documentName: upload.name,
            documentId: upload.docId
          },
        };
        req.push(mergereqNonJpn);
      }
    });
    this.updateDocTypeRequest = req;
  }

  updateDocType() {
    const requestObj = JSON.stringify({
      updateDocRequest: this.updateDocTypeRequest,
    });
    const options = this.createSubmitHeaders();
    const unrDocUploadURL = URL_PARAMETERS.COUNTRY.toLowerCase() === 'jp' ?
      this.config.baseURLJP +  apiEndPoints.uploadDocJP + "/docInfo" :
      this.config.apiEndPoints.unrUploadDocURL + "/docInfo";
    return this.http.put(unrDocUploadURL, requestObj, options);
  }

  getFeedbackRequestData(): any {
    return this.feedbackRequestData;
  }

  getDataByLevel(level, addressList: any[]): any {
    let data = "";
    addressList.forEach((element) => {
      if (element && element.hasOwnProperty("types")) {
        if (element.types.indexOf(level) > -1) {
          data = element.long_name;
        }
      }
    });
    return data;
  }
  getDataByCode(level, addressList: any[]): any {
    let data = "";
    addressList.forEach((element) => {
      if (element && element.hasOwnProperty("types")) {
        if (element.types.indexOf(level) > -1) {
          data = element.short_name;
        }
      }
    });
    return data;
  }

  createHeaders(): any {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "email-id": this.config.userEmailID,
        //'transactionId': this.headerTransactionId,
        //"Transfer-Encoding": "chunked",
        Authorization: "Basic " + this.config["authorizationKey_new"],
        // "Content-Type": "multipart/form-data;boundary="
      }),
    };
  }

  createdocHeaders(): any {
    return {
      headers: new HttpHeaders({
        "email-id": this.config.userEmailID,
        transactionId: this.headerTransactionId,
        Authorization: "Basic " + this.config["authorizationKey_new"]

        //   "Content-Type": "multipart/form-data;boundary="
      }),
    };
  }

  docHeaderJP(): any {
    return {
      headers: new HttpHeaders({
        transactionId: this.headerTransactionId,
      }),
    };
  }

  createSubmitHeaders(): any {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "email-id": this.config.userEmailID,
        transactionId: this.headerTransactionId,
        Authorization: "Basic " + this.config["authorizationKey_new"],
      }),
    };
  }

  createFeedbackHeaders() {
    return this.makeHttpOptionsObject("submit-feedback");
  }

  createClaimNumberHeaders() {
    return this.makeHttpOptionsObject("doc-upload");
  }

  makeHttpOptionsObject(resource) {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "email-id": this.config.userEmailID,
        transactionId: this.headerTransactionId,
        Authorization: "Basic " + this.config["authorizationKey_new"],
      }),
    };
  }

  setTransactionId(transactionId) {
    this.headerTransactionId = transactionId;
  }
  getTransactionId() {
    return this.headerTransactionId;
  }
  searchAllPolicy(req: any): any {
    const requestObj = JSON.stringify(req);
    const options = this.createHeadersJP();
    let searchPolicyURL = this.config.baseURLJP + apiEndPoints.policySearchJP + '?' + 'accountType=' + req.accountType
      + '&' + 'policyNo=' + req.policyNo + '&' + 'insuredName=' + req.insuredName + '&' + 'birthDate=' + req.birthDate + '&' + 'countryCd=JP';
    searchPolicyURL = req.dol ? searchPolicyURL += '&' + 'dol=' + req.dol : searchPolicyURL;

    return this.http.get(searchPolicyURL);
    //  return this.http.get('../../../assets/policy-details-mock-data.json');
  }
  checkUserExist(req: any): any {
    //const requestObj = JSON.stringify(req);
    const options = this.createHeadersJP();
    let searchPolicyURL = this.config.baseURLJP + apiEndPoints.checkUser + req


    return this.http.get(searchPolicyURL);
    //  return this.http.get('../../../assets/policy-details-mock-data.json');
  }


  searchClaimDetails(req: any): any {
    const requestObj = JSON.stringify(req);
    const options = this.createHeadersJP();
    const searchClaimURL = this.config.baseURLJP + apiEndPoints.claimSearchJP;
    return this.makeHttpPostCall(searchClaimURL, requestObj, options);
  }

  createHeadersJP(headers1?): any {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
    };
    return httpOptions;
  }
  docUploadRequest(req): any {
    const options = (URL_PARAMETERS.COUNTRY.toLowerCase() === '' ? this.cacheService.get('country').toLowerCase() : URL_PARAMETERS.COUNTRY.toLowerCase()) === 'jp' ? undefined : this.createdocHeaders();
    const unrDocUploadURL = (URL_PARAMETERS.COUNTRY.toLowerCase() === '' ? this.cacheService.get('country').toLowerCase() : URL_PARAMETERS.COUNTRY.toLowerCase()) === 'jp' ? this.config.baseURLJP + apiEndPoints.uploadDocJP : this.config.apiEndPoints.unrUploadDocURL;
    return this.makeHttpPostCall(unrDocUploadURL, req, options);
  }
  docUploadRequestNordics(req): any {
    const options =this.createdocHeaders();
    const unrDocUploadURL = this.config.apiEndPoints.unrUploadDocURL;
    return this.makeHttpPostCall(unrDocUploadURL, req, options);
  }
  logErrorAEM(eventName, req, res, from) {
    window["claimsDigital"][from]["req"] = req;
    window["claimsDigital"][from]["res"] = res;
    const event = new CustomEvent(eventName);
    document.body.dispatchEvent(event);
  }

  finalClaimSubmit(req) {
    const requestObj = JSON.stringify(req);
    const options = this.createSubmitHeaders();
    const unrFinalSubmitURL = this.config.apiEndPoints.setFnolURL;
    return this.http.put(unrFinalSubmitURL, requestObj, options);
  }

  deleteDocRequest(req): any {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",

      }),
      body: req,
    };
    const unrDeleteDocUploadURL = URL_PARAMETERS.COUNTRY.toLowerCase() === 'jp' ? this.config.baseURLJP +  apiEndPoints.uploadDocJP : this.config.apiEndPoints.unrUploadDocURL;
    return this.http.delete(unrDeleteDocUploadURL, options);
  }

  getUnrRdfEnumList(enumListModel): any {
    const unrRdfEnumURL =
      "assets/enumerationResponse/" + enumListModel + ".json";
    return this.http.get(unrRdfEnumURL);
  }

  getClaimNumber(request): Observable<object> {
    //const requestObj = JSON.stringify(request);
    const options = URL_PARAMETERS.COUNTRY.toLowerCase() === 'jp' ? this.docHeaderJP() : this.createClaimNumberHeaders();
    const unrGetClaimNumURL = URL_PARAMETERS.COUNTRY.toLowerCase() === 'jp' ? this.config.baseURLJP + apiEndPoints.getClaimNumberJP + URL_PARAMETERS.COUNTRY : this.config.apiEndPoints.unrGenerateClaimNumberCommon +
      (URL_PARAMETERS.COUNTRY || window.location.pathname.split("/")[3].toUpperCase());
    return this.http.get(unrGetClaimNumURL, options);
  }

  DeleteTempSavedClaim(UserID, request) {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",

      }),
      body: request
    };
    const baseURL = "https://dev.hip12api.aig.com/extgtw5/c2a/";
    let unrGetClaimNumURL = `${baseURL}${apiEndPoints.deleteSavedTempClaim}/${UserID}`;
    return this.http.delete(unrGetClaimNumURL, options);
  }

  getclaimantAllUIData() {
    return this.claimantAllUIData;
  }

  getclaimtypeAllUIData() {
    return this.claimtypeAllUIData;
  }

  getClaimTypeAllStructureData() {
    return this.claimTypeAllStructureData;
  }

  // getClaimNumberAuth(request): Observable<object> {
  //   //const requestObj = JSON.stringify(request);
  //   // const options = this.createClaimNumberHeaders();
  //   const unrGetClaimNumURL = this.config.baseURLJP + apiEndPoints.getClaimNumberJP + URL_PARAMETERS.COUNTRY;
  //   return this.http.get(unrGetClaimNumURL);
  // }
  getFnolRequestBody(
    type: string = "",
    policyDetailsData: any = {},
    phoneCodeListData: any = []
  ) {
    let allStepData = [];
    Object.keys(FNOL_STEPS_JP).map((val) => {
      if (val !== "DECLARATION") {
        allStepData.push(this.dataStoreService.get(FNOL_STEPS_JP[val].name));
      }
    });
    this.policyDetails = policyDetailsData;
    this.phoneCodeList = phoneCodeListData;

    const requestObj = [];
    commonSetFnolSgModel["applicationContext"]["countryCode"] = URL_PARAMETERS.COUNTRY;
    commonSetFnolSgModel["applicationContext"]["userId"] =
      this.cacheService.getUserId();
    commonSetFnolSgModel["claimsDetails"]["userId"] = this.cacheService.getUserId();
    commonSetFnolSgModel["claimsDetails"]["claimNumber"] = this.cacheService.getClaimNumber();
    commonSetFnolSgModel["claimsDetails"]["dateofIncident"] = moment(
      allStepData[0].uiData.accidentDate
    ).format("YYYY-MM-DD");

    requestObj.push(commonSetFnolSgModel);

    // requestObj.push(this.generateDocUploadData())


    return Object.assign({}, ...requestObj);
  }

  setFnolAuth(): Observable<object> {
    if(this.oneTimeCAll){
      return
    }
    const setFnolURL = this.config.baseURLJP + apiEndPoints.submitClaimJP;
    // const options = this.generateSetFnolHeader();
    const requestObj = this.getFnolRequestBody('setFnol');
    return this.makeHttpPostCall(setFnolURL, requestObj, {});
  }

seFnolApiOneTimeCall(data){
  this.oneTimeCAll =data
}


  generateSetFnolHeader(): any {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "email-id": this.config.userEmailID,
        transactionID: this.getTransactionId() || "",
        Authorization: "Basic " + this.config["authorizationKey_new"],
      }),
    };
  }

  setFnol(): Observable<object> {
    const setFnolURL = this.config.apiEndPoints.setFnolURL;
    const options = this.generateSetFnolHeader();
    const requestObj = "";
    return this.makeHttpPostCall(setFnolURL, requestObj, options);
  }

  generateSetFnolRequestNordics(){

     let applicationContext= {
      "countryCode": window.location.pathname.split("/")[3].toUpperCase(),
      "language": window.location.pathname.split("/")[4],
      "lineOfBusiness":  window.location.pathname.split("/")[5].toUpperCase(),
      "userId": this.config.userEmailID,
      "claimNumber": this.cacheService.getClaimNumber(),
      "subLob": "NA",
      "submissionDate": moment(new Date()).format("DD-MM-YYYY"),
      "submissionTime":  moment(new Date()).format("HH:mm:ss")
  }

  return applicationContext;

  }

  setFnolNordics(): Observable<object> {
    const setFnolURL = this.config.apiEndPoints.setFnolURL;
    const options = this.generateSetFnolHeader();
    const requestObj ={...submitClaim,applicationContext :this.generateSetFnolRequestNordics()};
    return this.http.post(setFnolURL, requestObj, options);
  }
  
  submitClaimNordicsAH(){
    const setFnolURL = this.config.apiEndPoints.setFnolURL;
    const options = this.generateSetFnolHeader();
    const requestObj =this.fnolNordicsDeclarationService.getRequestPayload();
    return this.http.put(setFnolURL, requestObj, options);
  }
  submitClaimNordicsHLF(){
    const setFnolURL = this.config.apiEndPoints.setFnolURL;
    const options = this.generateSetFnolHeader();
    const requestObj =this.fnolNordicsDeclarationHLFService.getRequestPayload();
    return this.http.put(setFnolURL, requestObj, options);
  }


  submitClaimNordics(){
    const setFnolURL = this.config.apiEndPoints.setFnolURL;
    const options = this.generateSetFnolHeader();
    const requestObj =this.nordics.getRequestPayload();
    return this.http.put(setFnolURL, requestObj, options);
  }



  getUserData(req) {
    GetUserData.GetUserDataReqType.UserId = req; //.toLowerCase();
    //	const requestObj = JSON.stringify(RegistrationConstant.getUserData_RequestBody);
    const getUserDataURL =
      this.config.baseURLJP + apiEndPoints.getUserDataJP + this.cacheService.getUserId();
      return this.http.get(getUserDataURL);
  }

  updateUserData(req) {
    updateUserData.profile = { ...req }; //.toLowerCase();
    //	const requestObj = JSON.stringify(RegistrationConstant.getUserData_RequestBody);
    const updateUserDataURL =
      this.config.baseURLJP + apiEndPoints.updateUserDataJP;
    return this.http.put(updateUserDataURL, updateUserData);
  }

  submitFeedback(request: IsubmitFeedbackModel) {
    const requestObj = JSON.stringify(request);
    const options = this.createFeedbackHeaders();
    const unrFeedBackNewURL = this.config.apiEndPoints.unrFeedBackNewURL;
    return this.makeHttpPostCall(unrFeedBackNewURL, requestObj, options);
  }

  claimSubmitJapan(req) {
    const requestObj = JSON.stringify(req);
    const options = this.createHeadersJP();
    const unrJPSubmitURL = this.config.baseURLJP + apiEndPoints.submitClaimJP;
    return this.http.put(unrJPSubmitURL, requestObj);
  }

  claimSaveTempJapan(req) {
    const requestObj = JSON.stringify(req);
    const options = this.createHeadersJP();
    const unrJPSubmitURL = this.config.baseURLJP + apiEndPoints.submitClaimJP;
    return this.http.post(unrJPSubmitURL, requestObj);
  }

  makeHttpPostCall(url, data, options): any {
    return this.http.post(url, data, options).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error || "Server error");
      })
    );
  }

  getAddWitnessData(index, getPosition, witnessObj, controlsToBeAddDelete) {
    const addWitnessData = JSON.parse(JSON.stringify(witnessObj)); // [...ClaimInfoData.ADDWITNESS];
    for (let i = 0; i < addWitnessData.length; i++) {
      if (addWitnessData[i].name === "thirdPartyWitness1Title") {
        addWitnessData[i].value = `Third Party/Witness ${getPosition}`;
      }
      if (
        addWitnessData[i].name === "customAddWitness" ||
        addWitnessData[i].name === "customDeleteWitness"
      ) {
        addWitnessData[i].index += index;
      }
      if (addWitnessData[i].name === "customDeleteWitness") {
        addWitnessData[i].controlsToDelete = controlsToBeAddDelete;
      }
      addWitnessData[i].name = `${addWitnessData[i].name}${index}`;
      addWitnessData[i]["index"] = index;
      if (addWitnessData[i].fields && addWitnessData[i].fields.length) {
        for (let j = 0; j < addWitnessData[i].fields.length; j++) {
          addWitnessData[i].fields[
            j
          ].name = `${addWitnessData[i].fields[j].name}${index}`;
          addWitnessData[i].fields[j]["index"] = index;
        }
      }
    }
    return addWitnessData;
  }

  setIsValidated(val: boolean) {
    this.isPolicyValidated = val
  }

  getIsValidated() {
    return this.isPolicyValidated
  }
  checkAccountNum(confirmNum, uiData, fieldName) {

    if(fieldName === "confirmAccountNumberN"){
      return (confirmNum === uiData["accountNumberN"] ? true : false);
    }else if(fieldName === "confirmAccountNumberF" ){
      return (confirmNum === uiData["accountNumberF"] ?  true : false);
    }else if(fieldName === "confirmAccountNumberN2"  ){
      return (confirmNum === uiData["accountNumberN2"] ?  true : false);
    }else if(fieldName === "confirmAccountNumberF2" ){
      return (confirmNum === uiData["accountNumberF2"] ?  true : false);
    }

  }

  public  loginUnlock(request,type) {
    // const requestObj = JSON.stringify(this.requestJP(request, policyDetails));
    const options = this.createHeadersJP();
    const baseURL = "https://dev2.hip12api.aig.com/extgtw5/c2a/";
     let submitRegistrationURL 
    if(type == 'unlock'){
      submitRegistrationURL  =
      this.config.baseURLJP  + apiEndPoints.unlockAccount;
    }else{
      submitRegistrationURL  =
      this.config.baseURLJP  + apiEndPoints.forgetPassword;
    }
   
    return this.makeHttpPostCall(
      submitRegistrationURL,
      request,
      options
    );
  }
}
